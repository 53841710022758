import request from './../request.js'

//setMealChange.vue
export function getAccountList(params){
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data:params
    })
}
export function getList(params){
    return request({
        url: 'card/list.do?',
        method: 'post',
        data:params
    })
}
export function getSetMeal(params){
    return request({
        url: 'meal/setmeal/getSetMealByCategory.do?',
        method: 'post',
        data:params
    })
}
export function updateMeal(params){
    return request({
        url: 'card/update.do?',
        method: 'post',
        data:params
    })
}

//setMealAllocation.vue  
export function getMealInfo(params){
    return request({
        url: 'meal/agentsetmeal/agentMeal.do?',
        method: 'post',
        data:params
    })
}
export function getsetmealByagentId(params){
    return request({
        url: 'meal/setmeal/getsetmealByagentId.do?',
        method: 'post',
        data:params
    })
}

export function getAgentList(params){
    return request({
        url: 'agent/list4agent.do?',
        method: 'post',
        data:params
    })
}
export function addSetMeal_(params){
    return request({
        url: 'meal/agentsetmeal/addSetMeal.do?',
        method: 'post',
        data:params
    })
}
export function deleteInterest_(params){
    return request({
        url: 'meal/agentsetmeal/deleteInterest.do?',
        method: 'post',
        data:params
    })
}
export function setMealEdit_(params){
    return request({
        url: 'meal/agentsetmeal/setMealEdit.do?',
        method: 'post',
        data:params
    })
}

//agentSetMeal.vue
export function getAgentMealList(params){
    return request({
        url: 'meal/agentsetmeal/agentMealList.do?',
        method: 'post',
        data:params
    })
}
export function exportAgentMealListExcelSync(params){
    return request({
        url: 'meal/agentsetmeal/exportAgentMealListExcelSync.do?',
        method: 'post',
        data:params
    })
}



//setMealAgentAllocation.vue

