<template>
	<div class="moreStatics">
		<el-row :gutter="20">
			<el-col :span="6">
				<div>
					<div class="title-box"><span class="m-title">代理商统计</span></div>
					<div class="content-box">
					
						<div class="rowone">
							<p>代理商总数</p>
								<p style="color: #09A2E3" id="agentCount">{{data.agentCount}}</p>
							</div>
							<div class="rowone">
								<p>本月新增代理商</p>
								<p style="color: #09A2E3" id="agentCountThisMonth">{{data.agentCountThisMonth}}</p>
								</div>
							<div class="rowone">
								<p>禁用代理商数</p>
								<p style="color: #09A2E3" id="agentCountDisabled">{{data.agentCountDisabled}}</p>
							</div>
							
						
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div>
					<div class="title-box"><span class="m-title">充值统计</span></div>
					<div class="content-box">
						
						<div class="rowone">
							<p>已充值卡</p>
								<p style="color: #09A2E3" id="cardCountRecharged">{{data.cardCountRecharged}}</p>
								</div>
								<div class="rowone">
									<p>未充值卡</p>
								<p style="color: #09A2E3" id="cardCountUnrecharge">{{data.cardCountUnrecharge}}</p>
							</div>
							<div class="rowone">
								<p>本月退款</p>
								<p style="color: #09A2E3" id="refundThisMonth">{{data.refundThisMonth}}</p>
								</div>
							<div class="rowone">
								<p>本月充值</p>
								<p style="color: #09A2E3" id="rechargeThisMonth">{{data.rechargeThisMonth}}</p>
							</div>
						
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div>
					<div class="title-box"><span class="m-title">充值金额统计</span></div>
					<div class="content-box">
						
						<div class="rowone">
							<p>本月平台充值</p>
								<p style="color: #09A2E3" id="rechargeAmountPlatThisMonth">{{data.rechargeAmountPlatThisMonth}}</p>
							</div>
								<div class="rowone">
									<p>上月平台充值</p>
								<p style="color: #09A2E3" id="rechargeAmountPlatPreMonth">{{data.rechargeAmountPlatPreMonth}}</p>
							</div>
							<div class="rowone">
								<p>本月代理商充值</p>
								<p style="color: #09A2E3" id="rechargeAmountAgentThisMonth">{{data.rechargeAmountAgentThisMonth}}</p>
							</div>
							<div class="rowone">
								<p>上月代理商充值</p>
								<p style="color: #09A2E3" id="rechargeAmountAgentPreMonth">{{data.rechargeAmountAgentPreMonth}}</p>
						</div>
		
						
					</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div>
					<div class="title-box"><span class="m-title">订单统计</span></div>
					<div class="content-box">
					
								<div class="rowone">
									<p>本月平台订单</p>
								<p style="color: #09A2E3" id="orderCountPlatThisMonth">{{data.orderCountPlatThisMonth}}</p>
							</div>
						<div class="rowone">
							<p>上月平台订单</p>
								<p style="color: #09A2E3" id="orderCountPlatPreMonth">{{data.orderCountPlatPreMonth}}</p>
						</div>
							<div class="rowone">
								<p>本月代理商订单</p>
								<p style="color: #09A2E3" id="orderCountAgentThisMonth">{{data.orderCountAgentThisMonth}}</p>
							</div>
							<div class="rowone">
								<p>上月代理商订单</p>
								<p style="color: #09A2E3" id="orderCountAgentPreMonth">{{data.orderCountAgentPreMonth}}</p>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row :gutter="20" style="margin-top:10px">
   <el-col :span="12">
				<div>
					<div class="title-box"><span class="m-title">激活率统计</span></div>
					<div class="content-box">
					   <div>
						  <el-form ref="form" :model="form" inline class="hook  themed-form search-form" label-width="75px">
						 <el-form-item label="开卡公司:">
							
						 <el-select v-model="form.accountId" filterable @change="activationRate">
							<el-option label="全部" value=""></el-option>
                        <el-option v-for="(item,index) in accountLists" :label="item.accountName" :value="item.id" :key="index"></el-option>
                        </el-select>
						</el-form-item>
						  <el-form-item label="代理商:">
                           <el-select v-model="form.agentId"  filterable @change="activationRate">
                        <el-option label="全部" value=""></el-option>
                        <el-option v-if="agentType!=0"    :label="agentThreeName" :value="loginAgentId"></el-option>
					    <el-option v-for="item in agentList" :key="item.id" :label="item.label" :value="item.value">
					</el-option>
					
				</el-select>
				</el-form-item>
				</el-form>
					   </div>
						<div class="rowone">
							<p>总激活</p>
								<p style="color: #09A2E3" >{{activeData.activeTotal}}</p>
							</div>
							<div class="rowone">
								<p>本月激活</p>
								<p style="color: #09A2E3" >{{activeData.activeCurMonth}}</p>
								</div>
							<div class="rowone">
								<p>昨天激活</p>
								<p style="color: #09A2E3" >{{activeData.activeLastDay}}</p>
							</div>
							
						
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import {getAccountList,} from '@/api/cardManagement/cardBill.js'
	import {list4main,list4active} from "@/api/welcome.js"
	 import {getAgentType} from "@/api/agentManagement.js"
	  import {getAgentList,} from '@/api/setMealManagment/setMealChange.js'
	export default{
		data(){
			return {
				data:{},
				form:{
                   accountId:"",
				   agentId:""
				},
				accountLists:[],
				agentList:[],
				activeData:{},
				loginAgentId:"",
				agentThreeName:"",
				agentType:""
			}
		},
		created(){
			this.getlist4main()
			this.getAgentType()
			this._getAgentList()
			this._getAccountList()
			this.getList4active()
		},
		
		methods:{
			activationRate(val){
            this.getList4active()
			},
			getList4active(){
           list4active(this.form).then(res=>{
              console.log(res)
			  this.activeData= res 

		   })
			},
			getlist4main(){
				list4main({}).then(res=>{
					this.data = res.entity
					this.data1 = res.entityRebate
				})
			},
			 _getAccountList(){
                getAccountList().then((res) => {
                    this.accountLists = res
                })
            },
			 _getAgentList(){
                getAgentList(this.form).then((res) => {
                    this.agentList = res.map((item)=>{
                        return {
                            label:item.name,
                            value:item.id
                        }
                    })
                })
            },
			getAgentType(){
				getAgentType().then(res=>{
                    this.agentThreeName = res.agentThreeName
                    this.loginAgentId = res.agentId
                     this.agentType = res.agentType
				})
            },
		}
	}
</script>

<style>
.moreStatics .rowone {
		display: flex;
		/* align-items: center; */
		justify-content: space-between;
		padding: 3px 0px;
	}
</style>
