/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

// export function getAccountList(){ //运营商 
//     return request({
//         url: '/system/cardOperator/getAccountListByAgent.do?',
//         method: 'post'
//     })
// }

export function agentListForUser(params){ // 代理商列表
    return request({
        url: '/agent/listForUser.do?',
        method: 'post',
        data:params,
    })
}
export function setmealListForUser(params){ // 套餐列表
    return request({
        url: '/meal/setmeal/listForUser.do?',
        method: 'post',
        data:params,
    })
}
export function getAccountList(params){ //运营商 
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post',
        data:params,
    })
}

export function listData(params){   //获取列表
    return request({
        url: 'card/list.do?',
        method: 'post',
        data:params
    })
}

export function checkExportCount(params){  //导出表格
    return request({
        url: 'card/exportExcelSync.do?',
        method: 'post',
        data:params
    })
}

export function checkExportVirtualCount(params){  //导出虚拟卡表格
    return request({
        url: 'card/invExportExcelSync.do?',
        method: 'post',
        data:params
    })
}

export function getagentList(params){  
    return request({
        url: 'agent/list4distriagents.do?',
        method: 'post',
        data:params
    })
}

export function distributionCard(params){
    return request({
        url: 'distributionagentcard/distributionCard.do?',
        method: 'post',
        data:params
    })
}

export function getSetMeal(params){   //获取套餐
    return request({
        url: 'meal/setmeal/getSetMealByCategory.do?',
        method: 'post',
        data:params
    })
}
export function getSetMealOne(params){   //获取套餐
    return request({
        url: 'meal/setmeal/getIccidsSetMealByCategory.do?',
        method: 'post',
        data:params
    })
}
export function getSetMealNew(params){   //获取套餐
    return request({
        url: '/meal/setmeal/getIccidsSetMealByCategory.do?',
        method: 'post',
        data:params
    })
}


export function batchRecharge(params){
    return request({
        url: 'card/batchRecharge.do?',
        method: 'post',
        data:params
    })
}

export function cardRecycleByExcel(params){
    return request({
        url: 'record/cardRecycleRecord/cardRecycleByExcel.do?',
        method: 'post',
        data:params
    })
}

export function deleteCard(params){
    return request({
        url: 'card/deleteCard.do?',
        method: 'post',
        data:params
    })
}

export function oneDownloadExport(params){  //导出生成一机一码
    return request({
        url: '/card/exportQrCode.do?',
        method: 'post',
        data:params
    })
}


